@media only screen and (max-width: 600px) {
  .pagination.react-bootstrap-table-page-btns-ul {
    margin: 10px !important;
  }
  .react-bootstrap-table-pagination-total {
    display: none !important;
  }
  th,
  td {
    font-size: 11px !important;
  }
}

.page-number:before {
  content: "Page: " counter(page);
}

.react-bootstrap-table {
  width: 100% !important;
  border-radius: 12px;
  background-color: white !important;
}

.table.table-bordered {
  width: 100% !important;
  padding: 20px !important;
}

thead {
  border-bottom: 1px !important;
  border-color: #99a799 !important;
}

tbody {
  margin: 0px 20px !important;
}

th {
  background-color: #f2f3f4 !important;
  font-weight: 400;
  font-size: 14px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  color: #757575 !important;
  width: fit-content;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

td {
  text-align: left;
  padding-left: 20px !important;
  padding-right: 5px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  font-weight: 400;
  font-size: 14px;
  color: #2d2d2d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row.react-bootstrap-table-pagination {
  margin-top: 1.5rem;
  font-size: 12px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  color: #7c7c7c;
}

.pagination.react-bootstrap-table-page-btns-ul {
  color: blue;
}

.pagination.react-bootstrap-table-page-btns-ul li {
  display: inline-flex !important;
  margin: 10px;
}

.react-bootstrap-table-pagination-total {
  margin-top: 5px !important;
}

.page-item {
  color: #be2b27;
}

.active.page-item {
  background-color: #be2b27;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 100%;
  color: white;
}

.dataTables_length {
  margin-top: 10px !important;
}

tr:nth-child(even) {
  background-color: #fafafa;
}
