@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,900");

@media only screen and (max-width: 768px) {
  div.fb_dialog_content iframe {
    display: none !important;
  }
}

* {
  font-family: "Poppins";
}

.customInput {
  @apply border border-neutralgray rounded-md py-1.5 focus:outline-none w-full px-2;
}

.mult-email-input__control {
  @apply border border-neutralgray rounded-md focus:outline-none shadow-none w-full  !important;
}

.gradientBg {
  @apply bg-gradient-to-t from-[#D6504E] to-[#FBA24B];
}

.stepBar {
  @apply leading-none w-[20%] delay-500 duration-500 ease-linear;
}

@layer utilities {
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
